<template>
  <v-sheet class="bg-transparent pa-12" rounded>
    <v-card class="mx-auto px-6 py-8" max-width="344">
      <v-form v-model="form" @submit.prevent="onSubmit">
        <v-text-field v-model="hotel" :readonly="loading" :rules="[required]" class="mb-2" clearable
          label="Hotel Name"></v-text-field>

        <v-text-field v-model="contact_name" :readonly="loading" :rules="[required]" class="mb-2" clearable
          label="Contact Person Name"></v-text-field>

        <v-text-field v-model="email" :readonly="loading" :rules="[required]" class="mb-2" clearable
          label="Email"></v-text-field>

        <v-text-field v-model="phone_number" :readonly="loading" :rules="[required]" class="mb-2" clearable
          label="Phone Number"></v-text-field>

        <v-text-field v-model="address" :readonly="loading" :rules="[required]" class="mb-2" clearable
          label="Address"></v-text-field>

        <v-select
          label="Select Reservation Source"
          :items="this.reservations"
          v-model="selectedReservation"
          item-title="source_title"
          item-value="id"
        >
        </v-select>

        <v-select
          label="Select Property Management System Source"
          :items="this.property_management_systems"
          v-model="selectedPropertyManagementSystem"
          item-title="source_title"
          item-value="id"
        >
        </v-select>
        
        <v-text-field v-model="password" :readonly="loading"
          :append-inner-icon="hide_password ? 'mdi-eye-off' : 'mdi-eye'" :type="hide_password ? 'password' : 'text'"
          prepend-inner-icon="mdi-lock-outline" :rules="[required]" label="Password" placeholder="Enter your password"
          @click:append-inner="hide_password = !hide_password">
        </v-text-field>

        <v-text-field v-model="confirmed_password" :readonly="loading"
          :append-inner-icon="hide_password ? 'mdi-eye-off' : 'mdi-eye'" :type="hide_password ? 'password' : 'text'"
          prepend-inner-icon="mdi-lock-outline" :rules="[required]" label="Confirm Password"
          placeholder="Enter your password again" @click:append-inner="hide_password = !hide_password">
        </v-text-field>
        <br>
        <v-btn :disabled="!form" :loading="loading" block color="deep-orange-accent-4" size="large" type="submit"
          variant="elevated">
          Register
        </v-btn>
      </v-form>
    </v-card>
  </v-sheet>
</template>
<script>
export default {
  data: () => ({
    hostUrl: process.env.VUE_APP_HOST_URL,
    hide_password: true,
    form: false,
    reservations: [],
    property_management_systems: [],
    selectedReservation: null,
    selectedPropertyManagementSystem: null,
    hotel: null,
    contact_name: null,
    phone_number: null,
    address: null,
    email: null,
    password: null,
    confirmed_password: null,
    loading: false,
  }),

  created() {
    //${this.hostUrl}
    fetch(`${this.hostUrl}/api/randpmanager`, {
      method: 'GET',
    })
    .then(resp => resp.json())
    .then(resp => this.randpmanager = resp)
    .then(resp => {
      this.reservations = resp.reservations
      this.property_management_systems = resp.pmanager
    })
    .catch(error => console.log(error));

  },

  methods: {
  
    async onSubmit() {
      if (!this.form) return

      this.loading = true
      //mine
      const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({user:{ username: this.email, email: this.email, password: this.password }, hotel_name: this.hotel, contact_name: this.contact_name, phone_number: this.phone_number, address: this.address, reservation_system: this.selectedReservation, property_management: this.selectedPropertyManagementSystem })
      }
      console.log(JSON.stringify({user:{ username: this.email, email: this.email, password: this.password }, hotel_name: this.hotel, contact_name: this.contact_name, phone_number: this.phone_number, address: this.address, reservation_system: this.selectedReservation, property_management: this.selectedPropertyManagementSystem }))
      const response = await fetch(`${this.hostUrl}/api/signup`, requestOptions)
      this.loading = false
      if (response.status == 200) {
        const resp = await response.json()
        this.$store.commit('login', resp.token)
        this.$router.push({name: 'home'})
      }
    },
    required(v) {
      return !!v || 'Field is required'
    },
  },
}
</script>