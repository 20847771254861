<template>
    <v-col cols="12" md="9" lg="8" class="mx-auto">
        <v-col class="text-h4  font-weight-black text-center">
            <br>
            <br>
            <p>
                Welcome to Pulse Price Application!
            </p>
            <br>
            <p class="text-h5 text-grey-lighten-1">Start your journey towards revolutionary revenue management with our
                <span class="text-white font-weight-bold">Adaptive Pricing Algorithm</span>.
            </p>

            <p class="text-h5 text-grey-lighten-1">
                Set up your environment and start using the <span class="text-white font-weight-bold">Adaptive Pricing
                    Algorithm</span>.
            </p>

        </v-col>
        <v-card class="mx-auto px-6 py-8 bg-transparent" variant="flat" max-width="344">
            <v-form v-model="form" @submit.prevent="onSubmit">
                <v-text-field variant="underlined" class="bg-transparent" v-model="data_url" :readonly="loading"
                    :type="text" :rules="[required]" label="Bookings data url" placeholder="">
                </v-text-field>
                <br>
                <v-btn :disabled="!form" :loading="loading" block color="deep-orange-accent-4" size="large" type="submit"
                    variant="elevated">
                    Initialize
                </v-btn>
            </v-form>
        </v-card>
    </v-col>
    <v-dialog v-model="dialog_message" transition="dialog-bottom-transition" width="auto">
        <v-card>
            <v-toolbar
              color="deep-orange-accent-2"
              title="Initialize"
            ></v-toolbar>
            <v-card-text>
              <div class="text-h5 pa-12">{{ this.response_message }}</div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                variant="text"
                @click="this.dialog_message = false;"
              >Close</v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_error" transition="dialog-bottom-transition" width="auto">
        <v-card>
            <v-toolbar
              color="red-darken-4"
              title="Error"
            ></v-toolbar>
            <v-card-text>
              <div class="text-h5 pa-12">{{ this.error_message }}</div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                variant="text"
                @click="this.dialog_error = false"
              >Close</v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        data_url: "https://hpo.xsada.net/history.json",
        hostUrl: process.env.VUE_APP_HOST_URL,
        loading: false,
        form: false,
        dialog_message: false,
        dialog_error: false,
        response_message: null,
        error_message: null
    }),
    methods: {
        onSubmit() {
            this.init_update(0, this.data_url)
        },
        async init_update(type, json_file) {
            console.log(this.hosturl)
            this.loading = true
            const requestOptions = {
                method: 'POST',
                headers: {
                    "Authorization": "Token " + this.$store.state.accessToken,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ source_url: json_file, source_type: type })
            }
            const response = await fetch(`${this.hostUrl}/api/init_update`, requestOptions)
            this.loading = false
            if (response.status === 200) {
                const resp = await response.json()
                this.response_message = resp['message']
                this.dialog_message = true
            } else {
                const resp = await response.json()
                this.error_message = resp.error
                this.dialog_error = true
            }
        }

    }
}
</script>
<style scoped></style>