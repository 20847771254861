import { createStore } from 'vuex'

export default createStore({
  state: {
    accessToken: null,
    new_range_data: []
  },
  getters: {
  },
  mutations: {
    login(state, token) {
      state.accessToken = token
      localStorage.setItem('accessToken', token)
    },
    logout(state) {
      state.accessToken = ""
      localStorage.removeItem('accessToken')
    },
    initializeStore(state) {
      if (localStorage.getItem('accessToken')) {
        state.accessToken = localStorage.getItem('accessToken')
        state.new_range_data = localStorage.getItem('new_range_data')
        //console.log(state.res_array)
      }
    },
    update(state, new_range_data) {
      state.res_array = new_range_data
      localStorage.setItem('new_range_data', JSON.stringify(new_range_data))
    }
    
  },
  actions: {
  },
  modules: {
  }
})
