<template>
    <nav :loading="loading">
        <v-col cols="12" md="9" lg="8" class="mx-auto">
            <v-toolbar class="bg-transparent">
                <v-toolbar-title>
                    <v-avatar class="mr-sm-2" size="64">
                        <v-img class="bg-white" contain :src="require(`@/assets/images/logo.jpg`)"></v-img>
                    </v-avatar>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-for="menuItem in menuItems" :key="menuItem.title" class="font-weight-black text-upppercase"
                    v-on:click="this.$router.push({ name: menuItem.link })">
                    {{ menuItem.title }}
                </v-btn>
                <v-btn v-if="this.$store.state.accessToken" class="font-weight-black text-upppercase"
                    v-on:click="this.logout">
                    Logout
                </v-btn>

            </v-toolbar>
        </v-col>
    </nav>
</template>

<script>

export default {
    name: 'HPONavbar',
    data() {
        return {
            loading: false,
            hostUrl: process.env.VUE_APP_HOST_URL,
        }
    },
    created() {
        console.log(this.$store.state.accessToken)
    },
    computed: {
        menuItems() {
            console.log(this.$store.state.accessToken)
            let menuItems = [
                { title: "Home", link: "home" },
                { title: "Signup", link: "signup" },
                { title: "Login", link: "login" },
            ]
            if (this.$store.state.accessToken) {
                menuItems = [
                    { title: "Home", link: "home" },
                    { title: "Initialize", link: "initialize" },
                    { title: "Update", link: "update" },
                    { title: "Results", link: "results" },
                ]
            }
            return menuItems
        }
    },
    methods: {
        async logout() {
            this.loading = true
            const requestOptions = {
                method: 'POST',
                headers: { "Authorization": "Token " + this.$store.state.accessToken },
            }
            const response = await fetch(`${this.hostUrl}/api/logout`, requestOptions)
            this.loading = false
            //if (response.status == 200) {
                const resp = await response.json()
                console.log(resp.message)
                this.$store.commit('logout')
            //}
        },
        
        navigateToRegister() {
            this.$router.push({ name: 'signup' })
        },
        navigateToLogin() {
            this.$router.push({ name: 'login' })
        },
        navigateToHome() {
            this.$router.push({ name: 'home' })
        },
        navigateToInit() {
            this.$router.push({ name: 'initialize' })
        },
        navigateToUpdate() {
            this.$router.push({ name: 'update' })
        },
        navigateToResults() {
            this.$router.push({ name: 'results' })
        }
    }
}
</script>
