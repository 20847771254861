import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView'
import RegisterView from '../views/RegisterView'
import InitView from '../views/InitView'
import UpdateView from '../views/UpdateView'
import ResultsView from '../views/ResultsView'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/signup',
    name: 'signup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: RegisterView
  },
  {
    path: '/initialize',
    name: 'initialize',
    component: InitView
  },
  {
    path: '/update',
    name: 'update',
    component: UpdateView
  },
  {
    path: '/results',
    name: 'results',
    component: ResultsView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
