<template>
  <v-sheet class="bg-transparent pa-12 my-auto" height="1200" rounded>
    <v-card flat class="mx-auto px-6 py-8" max-width="344">
      <v-form v-model="form" @submit.prevent="onSubmit">
        <v-text-field v-model="email" :readonly="loading" :rules="[required]" class="mb-2"
          prepend-inner-icon="mdi-email-outline" label="Email"></v-text-field>

        <v-text-field v-model="password" :readonly="loading"
          :append-inner-icon="hide_password ? 'mdi-eye-off' : 'mdi-eye'" :type="hide_password ? 'password' : 'text'"
          prepend-inner-icon="mdi-lock-outline" :rules="[required]" label="Password" placeholder="Enter your password"
          @click:append-inner="hide_password = !hide_password">
        </v-text-field>

        <br>

        <v-btn :disabled="!form" :loading="loading" block color="deep-orange-accent-4" size="large" type="submit"
          variant="elevated">
          Login
        </v-btn>

      </v-form>
      <v-alert v-if="loginError" type="error" closable>
        Incorrect username or password. Please try again.
      </v-alert>
    </v-card>
  </v-sheet>
</template>
<script>
export default {
  data: () => ({
    hostUrl: process.env.VUE_APP_HOST_URL,
    hide_password: true,
    form: false,
    email: null,
    password: null,
    loading: false,
    loginError: false
  }),

  methods: {
    async onSubmit() {
      if (!this.form) return
      this.loginError = false
      this.loading = true
      //mine
      try {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username: this.email, password: this.password })
        };
        const response = await fetch(`${this.hostUrl}/api/login`, requestOptions);
        if (response.status === 200) {
          const resp = await response.json();
          this.$store.commit('login', resp.token);
          this.$router.push({ name: 'home' });
        } else {
          this.loading = false
          this.loginError = true; // Display error message
        }
      } catch (error) {
        this.loading = false
        console.error('Error during login:', error);
        this.loginError = true; // Display error message
      }
      //console.log(resp.account.json())

      //setTimeout(() => (this.loading = false), 2000)
    },
    required(v) {
      return !!v || 'Field is required'
    },
  },
}
</script>