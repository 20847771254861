<template>
    <v-col cols="12" md="9" lg="8" class="mx-auto">
        <v-col class="text-h4  font-weight-black text-center">
            <br>
            <br>
            <p>
                Your Newly Updated Single Night Rates!
            </p>
            <br>
            <p class="text-h5 text-grey-lighten-1">
                Here are your new and old rates, neatly presented for your review.
            </p>    
        </v-col>
        <!-- <v-table class="uyghur">
            <thead class="bg-deep-orange-accent-2">
                <tr>
                    <th class="text-center" width="25%">
                        Date
                    </th>
                    <th class="text-center" width="25%">
                        Day
                    </th>
                    <th class="text-center" width="25%">
                        Old Rate
                    </th>
                    <th class="text-center" width="25%">
                        New Rate
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in rates" :key="item.id">
                    <td>{{ item.date_value }}</td>
                    <td>{{ item.day_of_week }}</td>
                    <td>{{ item.old_rate }}</td>
                    <td>{{ item.new_rate }}</td>
                </tr>
            </tbody>
        </v-table> -->
        <v-calendar ref="calendar" :events="this.events" v-model="this.today" type="month">

        </v-calendar>
    </v-col>
</template>
<script>
import { VCalendar } from 'vuetify/labs/VCalendar'
export default {
    components: {
        VCalendar,
    },
    data: () => ({
        hostUrl: process.env.VUE_APP_HOST_URL,
        today: null,
        rates: [],
        events: []
    }),
    created() {
        const requestOptions = {
                method: 'GET',
                headers: {
                    "Authorization": "Token " + this.$store.state.accessToken,
                },
            }
        fetch(`${this.hostUrl}/api/rates`, requestOptions)
        .then(resp => resp.json())
        .then(resp => {
            this.rates = resp
            this.today = new Date(this.rates[0].date_value)
            const events = []
            for (let i = 0; i < this.rates.length; i++) {
                const item = this.rates[i]
                const btime = '00:00:00'
                const etime = '23:59:59'
                var bdt = new Date(`${item.date_value}T${btime}`);
                var edt = new Date(`${item.date_value}T${etime}`);
                console.log(bdt)
                events.push({
                    title: `Old Rate: ${item.old_rate}`,
                    start: bdt,
                    end: edt,
                    color: "#a9a9a9",
                    allDay: true,
                })
                events.push({
                    title: `New Rate: ${item.new_rate}`,
                    start: bdt,
                    end: edt,
                    color: "#00aa00",
                    allDay: true,
                })
            }
            this.events = events

        })
        .catch(error => console.log(error))
    },
}
</script>
<style scoped></style>