<template>
  <v-col v-for="item in sectionItems(1)" :key="item.id" cols="12" md="9" lg="8" class="mx-auto">
    <v-col class="text-h4  font-weight-black text-center">
      {{ item.section_title }}
    </v-col>
    <v-spacer></v-spacer>
    <v-col class="text-h5 text-center">
      {{ item.content }}
    </v-col>
    <br /><br />
    <v-col class="text-center mx-auto">
      <v-btn size="large" class="custom-outlined-button" variant="outlined rounded-pill">
        Kickstart your success today!
      </v-btn>
    </v-col>
    <br /><br />
    <br /><br />
    <div v-for="item in sectionItems(2)" :key="item.id">
      <v-col class="text-md-h5 text-lg-h4 text-h5  font-weight-black text-center text-deep-orange-accent-2">
        {{ item.section_title }}
      </v-col>
      <v-row>
        <v-col cols="3" md="3" lg="2">
          <v-img aspect-ratio="1" cover :src="item.image"></v-img>
        </v-col>
        <v-col cols="9" md="9" lg="10" class="text-md-h6 text-lg-h5 text-h6">
          <v-col>
            {{ item.content }}
          </v-col>
        </v-col>
      </v-row>
    </div>
    <br /><br />
      <v-col class="text-md-h5 text-lg-h4 text-h5  font-weight-black text-center text-deep-orange-accent-2">
        {{ sectionItems(3).section_title }}
      </v-col>
      <br />
      <v-row>
        <v-col v-for="item in sectionItems(3)" :key="item.id" cols="6" sm="3" align-self-center>
          <v-card outlined variant="outlined" elevation="0" class="rounded-pill vx-auto feature">
            <v-img aspect-ratio="1" cover :src="item.image" class="pa-2 "></v-img>
          </v-card>
          <div class="text-subtitle-1 text-center mt-3">{{ item.title }}</div>
        </v-col>
      </v-row>
    <br /><br />
    <v-col class="text-md-h5 text-lg-h4 text-h5  font-weight-black text-center text-deep-orange-accent-2">
      {{ sectionItems(4).section_title }}
    </v-col>
    <br />
    <v-row>
      <v-col v-for="item in sectionItems(4)" :key="item.id" cols="6" sm="4">
        <v-card outlined variant="outlined" elevation="0" class="vx-auto feature">
          <v-img aspect-ratio="1" cover :src="item.image" class="pa-2 "></v-img>
        </v-card>
        <div class="text-subtitle-1 mt-3 wrap-text">{{ item.content + "\n" + item.title }}</div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { defineComponent } from 'vue';

// Components
//import HelloWorld from '../components/HelloWorld.vue';
/*
export default defineComponent({
  name: 'HomeView',
  data() {
    return {
      clients: []
    }
  },
  created() {
    fetch('http://172.104.143.75:8004/api/', {
      method: 'GET',
    }).then( resp => resp.json() )
    .then( resp => this.clients = resp )
    .catch( error => console.log(error) )
  }
});
*/

export default defineComponent({
  name: 'HomeView',

  components: {
  },

  data() {
    return {
      pageContent: [],
      hostUrl: process.env.VUE_APP_HOST_URL
    }
  },

  created() {
    fetch(`${this.hostUrl}/api/`, {
      method: 'GET',
    }).then(resp => resp.json())
      .then(resp => this.pageContent = resp)
      .catch(error => console.log(error))
  },

  methods: {
    sectionItems(section) {
      return this.pageContent.filter(item => item.section_id === section);
    },
  },
});
</script>
<style scoped>
.custom-outlined-button:hover {
  /* Change the color on hover */
  /* color: #ffffff; Change to your desired text color */
  border-color: #FF6E40;
  /* Change to your desired border color */
  color: #FF6E40 !important;
}

.feature {
  border: 2px solid lightgrey;
}

.feature:hover {
  border: 2px solid #FF6E40;
}

.wrap-text {
  white-space: pre-wrap;
}
</style>